.restaurant-info____modal-container {
  height: 100%;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.restaurant-info__delivery-time {
  font-size: 0.75rem;
}
