.web-confirm {
  height: 80%;
  width: 100%;
  padding: 16px;
}

.web-confirm-main-container {
  height: 100%;
  width: 100%;
  /* padding: 16px; */
  max-width: 1024px;
  margin: auto;
}
.web-confirm__success-container {
  /* background-color: red; */
  color: var(--ion-color-success);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 26px;
  text-align: center;
  max-width: 650px;
  margin: auto;
}
.web-confirm__success-container ion-icon {
  font-size: 96px;
  margin-bottom: 64px;
}
.web-confirm__success-container__phone {
  margin-top: 18px;
  font-size: 16px;
  color: var(--ion-color-dark);
}
.web-confirm__success-container__info {
  margin-top: 18px;
  font-size: 18px;
  color: var(--ion-color-dark);
}
.web-confirm__error-container {
  color: var(--ion-color-danger);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 26px;
  text-align: center;
  max-width: 650px;
  margin: auto;
}
.web-confirm__error-container ion-icon {
  font-size: 96px;
  margin-bottom: 64px;
}
.web-confirm__error-container__phone {
  margin-top: 16px;
  font-size: 16px;
  color: var(--ion-color-dark);
}

.web-confirm__warning-container {
  color: var(--ion-color-infos);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 26px;
  text-align: center;
  max-width: 650px;
  margin: auto;
}
.web-confirm__warning-container ion-icon {
  font-size: 96px;
  margin-bottom: 64px;
}
.web-confirm__warning-container__phone {
  margin-top: 16px;
  font-size: 16px;
  color: var(--ion-color-dark);
}

.web-confirm__success-container-button {
  margin: 2rem 0;
}
