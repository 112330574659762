.products-grid {
  width: 100%;
  margin: auto;
  max-width: 1024px;
  padding: 0 1rem;
  font-size: 1.125rem;
  font-weight: bold;
  color: var(--ion-color-secondary);
}

.products-category {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  /* background-color: blue; */
}

.products-category--order-mode {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  overflow-y: scroll;
  /* background-color: blue; */
}

.products-grid--order-mode {
  /* padding: 0; */
  padding: 0 16px;
}

.products-category--order-mode::-webkit-scrollbar {
  -webkit-appearance: none;
}

.products-category--order-mode::-webkit-scrollbar:vertical {
  width: 6px;
}

.products-category--order-mode::-webkit-scrollbar:horizontal {
  height: 6px;
}

.products-category--order-mode::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

.products-category--order-mode::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffffff;
}

.products-grid__grid {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, calc((100% - 1rem) / 2));
  grid-gap: 1rem;
  padding: 1rem 0;
  align-items: center;
  justify-items: center;
  /* background-color: blue; */
}
/* .products-grid__grid--order-mode {
  overflow-y: scroll;
} */
@media (min-width: 540px) {
  .products-grid__grid {
    grid-template-columns: repeat(3, calc((100% - 2rem) / 3));
  }
}
@media (min-width: 730px) {
  .products-grid__grid {
    grid-template-columns: repeat(4, calc((100% - 3rem) / 4));
  }
}

@media (min-width: 870px) {
  .products-grid__grid {
    grid-template-columns: repeat(5, calc((100% - 3rem) / 5));
  }
}

@media (min-width: 1024px) {
  .products-grid__grid {
    grid-template-columns: repeat(6, calc((100% - 6rem) / 6));
  }
  .products-grid__grid--order-mode {
    grid-template-columns: repeat(4, calc((100% - 3rem) / 4));
  }
}

/* @media (min-width: 1450px) {
  .products-grid__grid {
    grid-template-columns: repeat(8, calc((100% - 6rem) / 8));
  }
  .products-grid__grid--order-mode {
    grid-template-columns: repeat(6, calc((100% - 5rem) / 6));
  }
} */
