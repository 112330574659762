.category-chip {
  width: 100%;
  position: relative;
  padding: 0.5rem 1rem;
  margin: 0 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  border-radius: 8px;
  transition: all 150ms ease-in-out;
  cursor: pointer;
  white-space: nowrap;
}

.category-chip--muted {
  opacity: 0.3;
}
