.shop-page {
  height: 100%;
  width: 100%;
  /* padding: 16px; */
  max-width: 1024px;
  margin: auto;
  /* -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; */
}

.shop-page--closed {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 2rem;
  text-align: center;
  font-weight: bold;
}
