.orders-products-selector {
  width: 100%;
  height: 80%;
  position: relative;
  display: flex;
  justify-content: space-between;
  /* padding: 0 16px; */
}
.orders-products-selector__products {
  width: 100%;
  overflow-y: scroll;
  padding: 0 0 135px 0;
}

.orders-products-selector__full-cart-container {
  display: none;
  content: "";
  min-width: 288px;
  max-width: 288px;
  height: 100%;
  /* margin-left: 16px; */
}
.orders-products-selector__mini-cart-container {
  position: absolute;
  height: 112px;
  bottom: 0;
  left: 0;
}
@media screen and (min-width: 1024px) {
  .orders-products-selector__products {
    padding: 0 2px;
  }
  .orders-products-selector__full-cart-container {
    display: block;
  }
  .orders-products-selector__mini-cart-container {
    display: none;
  }
}
