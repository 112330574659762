.orders-confirmation {
  /* background-color: red; */
  height: calc(100% - 88px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.orders-confirmation__button-container {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
}
.orders-confirmation__icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.orders-confirmation__icon ion-icon {
  color: var(--ion-color-success);
  font-size: 128px;
}
.orders-confirmation__icon__text {
  font-weight: bold;
  margin-top: 16px;
  font-size: 20px;
}
.orders-confirmation__number {
  font-weight: bold;
  font-size: 24px;
}
