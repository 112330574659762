.orders-planned-order-type-selector {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 82%;
  width: 100%;
  margin: auto;
  max-width: 384px;
  font-size: 1.125rem;
  font-weight: bold;
  padding: 1%;
  color: var(--ion-color-secondary);
}

.orders-planned-order-type-selector__selector-single-container {
  position: relative;
}

.orders-planned-order-type-selector__selector--disabled {
  opacity: 0.4;
  filter: grayscale(100%);
}
.orders-planned-order-type-selector__disabled {
  position: absolute;
  left: 0px;
  top: 40%;
  right: 0px;
  bottom: 0px;
  font-size: 1rem;
  font-weight: 600;
  padding: 0 0.5rem;
  text-align: center;
}
.orders-planned-order-type-selector__selector-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}
.orders-planned-order-type-selector__selector {
  cursor: pointer;
  transition: opacity 250ms ease;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  padding: 1rem;
  border-radius: 2rem 4px 2rem 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 180px;
  justify-content: center;
  text-align: center;
  margin: 1rem 0;
}
.orders-planned-order-type-selector__selector__icon {
  min-height: 56px;
  width: 96px;
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
  font-size: 3rem;
}

.orders-planned-order-type-selector__selector__label {
  color: var(--ion-color-secondary);
  font-weight: 700;
  font-size: 1.125rem;
}
.orders-planned-order-type-selector__selector--active {
  background-color: var(--ion-color-primary);
  color: white;
}
.orders-planned-order-type-selector__selector--active
  .orders-planned-order-type-selector__selector__label {
  color: white;
}

.orders-planned-order-type-selector__button-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* margin: auto; */
}

.orders-planned-order-type-selector__button-container div {
  width: 42%;
}
