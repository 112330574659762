.orders-web-payment {
  /* background-color: red; */
  height: calc(100% - 88px);
  /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
  padding: 0px 16px;
  max-width: 600px;
  margin: auto;
}

.orders-web-payment__failed-container {
  height: calc(100% - 88px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  max-width: 600px;
  margin: auto;
}

.orders-web-payment__failed {
  padding: 2rem 0;
}
.orders-web-payment__form {
  /* box-shadow: var(--base-card-shadow); */
  padding: 0 16px;
  padding-bottom: 12px;
  /* border-radius: 8px; */
}
.orders-web-payment__order-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.orders-web-payment__order-line-text {
  font-size: 14px;
  font-style: italic;
}
.orders-web-payment__form__title {
  font-size: 20px;
  font-weight: bold;
  color: #364051;
}
.orders-web-payment__form__recap-title {
  background-color: var(--ion-color-secondary);
  margin: 16px 0;
  padding: 0.6rem;
  border-radius: 8px;
  font-weight: bold;
  color: #fff;
  display: flex;
  flex-direction: column;
}
.orders-web-payment__form__recap-title span {
  font-weight: normal;
}
.orders-web-payment__form__recap-title span:first-of-type {
  margin-top: 4px;
}

.orders-web-payment-app-loading-container {
  display: flex;
  width: 100%;
  height: 300px;
  justify-content: center;
  align-items: center;
  flex: 1;
}
