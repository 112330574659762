.address-fetcher {
  position: relative;
}
.address-fetcher__results-frame {
  height: 150px;
  width: 100%;
  background-color: var(--ion-color-light-tint);
  margin: 0.5rem 0 0;
  border-radius: 0.3rem;
  overflow-y: scroll;
  padding: 0 0.5rem;
}

.address-fetcher--no-selection {
  border-width: 2px;
  border-style: solid;
  border-color: red;
  border-radius: 0.3rem;
}

.address-fetcher--no-selection__warning {
  font-size: 0.9rem;
  font-weight: 200;
  color: red;
  margin: 0 0 0.5rem;
}
.address-fetcher__results-frame__result {
  cursor: pointer;
  background-color: var(--ion-background-color);
  margin: 0.5rem 0;
  padding: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 0.5rem);
}
.address-fetcher__results-frame__result--is-active {
  color: white;
  background-color: var(--ion-color-primary);
}
.address-fetcher__results-frame__no-results {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 16px;
  font-weight: bold;
  opacity: 0.5;
}
.address-fetcher__results-frame__loading {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 16px;
  font-weight: bold;
}
