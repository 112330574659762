.products-category-selector {
  width: 100%;
  margin: auto;
  max-width: 1024px;
  padding: 0 1rem;
  font-size: 1.125rem;
  font-weight: bold;
  color: var(--ion-color-secondary);
  background-color: var(--ion-color-secondary);
  position: sticky;
  top: 0;
  z-index: 1;
}
.products-category-selector--order-mode {
  padding: 0;
  /* padding: 0 16px; */
}
.products-category-selector__category-list {
  display: flex;
  padding: 1rem 0;
  overflow-x: auto;
}

/* .products-category-selector__category-list::-webkit-scrollbar {

  height: 10px;
  border-radius: 2rem;
}


.products-category-selector__category-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 2rem;
}

.products-category-selector__category-list::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 2rem;
}


.products-category-selector__category-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}  */
