.order-summary-item {
  padding: 0.5rem;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0.5rem;
  color: var(--ion-color-secondary);
}
.order-summary-item__base-product-infos {
  display: flex;
  justify-content: space-between;
}
.order-summary-item__base-product-infos__product-picture {
  height: 32px;
  min-width: 32px;
  width: 32px;
  border-top-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid var(--ion-color-primary);
  overflow: hidden;
  margin-right: 0.5rem;
}
.order-summary-item__base-product-infos__product-picture ion-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.order-summary-item__base-product-infos__name {
  font-weight: bold;
  font-size: 0.825rem;
  width: 100%;
}
.order-summary-item__base-product-infos__price {
  font-weight: bold;
  font-size: 0.825rem;
  margin-left: 0.5rem;
  align-self: flex-end;
}
.order-summary-item__options {
  padding: 8px 0 0 0;
}
.order-summary-item__options__item {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  opacity: 0.7;
  font-size: 12px;
  padding-left: 40px;
}
.order-summary-item__options__item__name {
  display: flex;
  align-items: center;
}
.order-summary-item__options__item__dot {
  height: 8px;
  width: 8px;
  background-color: var(--ion-color-secondary);
  border-radius: 100%;
  margin-right: 8px;
}
