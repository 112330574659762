.order-summary {
  height: 100%;
  width: 100%;
}
.order-summary__cart {
  height: calc(100% - 112px);
  width: 100%;
  background-color: var(--ion-color-light-tint);
  margin: auto;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.order-summary__cart--as-delivery {
  height: calc(100% - 16px);
}
.order-summary__products {
  padding: 8px;
  overflow-y: scroll;
  height: 100%;
}
.order-summary__total {
  height: 32px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  font-weight: bold;
  color: white;
  background-color: var(--ion-color-secondary);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.order-summary__products__spacer {
  width: 100%;
  height: 1px;
}
.order-summary__mini-infos {
  min-height: 45px;
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-summary__mini-infos__total-amount,
.order-summary__mini-infos__distance,
.order-summary__mini-infos__hour {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--ion-color-primary);
  height: calc(100% - 0.75rem);
}
.order-summary__mini-infos__total-amount > span,
.order-summary__mini-infos__distance > span,
.order-summary__mini-infos__hour > span {
  color: var(--ion-color-secondary);
  margin-left: 0.5rem;
  font-size: 0.75rem;
  font-weight: bold;
}
.order-summary__mini-infos__distance {
  border-right: 1px solid var(--ion-color-light-shade);
}
