.sign-in {
  /* background-color: red; */
  height: calc(100% - 88px);
  /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
  padding: 16px;
  max-width: 600px;
  margin: auto;
}

.sign-in__form-toogle {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.6rem;
}

.sign-in__form {
  box-shadow: var(--base-card-shadow);
  padding: 16px;
  border-radius: 8px;
}

.sign-in__form__small-toggle {
  padding-left: 0.5rem;
}

.sign-in__form__title {
  font-size: 20px;
  font-weight: bold;
  color: #364051;
}

.sign-in__form__small {
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  color: #364051;
  text-decoration: underline;
}

.sign-in__form__small:hover {
  color: var(--ion-color-primary);
  text-decoration: underline;
}

#recaptcha-container {
  margin: 1rem 0;
}
