.orders-is-a-delivery-selector {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* flex:1; */
  width: 100%;
  height: 82%;
  margin: auto;
  max-width: 384px;
  font-size: 1.125rem;
  padding: 1%;
  font-weight: bold;
  color: var(--ion-color-secondary);
}

.orders-is-a-delivery-selector__selector {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}
@media screen and (min-height: 600px) {
  .orders-is-a-delivery-selector {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-between;
  }
}

.orders-is-a-delivery-selector__button-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* margin: auto; */
}

.orders-is-a-delivery-selector__button-container div {
  width: 42%;
}
