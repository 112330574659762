.order-tracking__modal-container {
    height: 100%;
    width: 100%;
    padding : 1rem;

    
}

.order-tracking__modal-order-container {
    /* height: 100%;
    width: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
ion-modal.fullscreen {
    --width: 100%;
    --height: 100%;
    --border-radius: 0;

  }