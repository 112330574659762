.orders-full-cart {
  height: 100%;
  width: 100%;
}
.orders-full-cart__cart {
  height: 100%;
  width: 100%;
  background-color: var(--ion-color-light-tint);
  margin: auto;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.orders-full-cart__products {
  padding: 8px;
  overflow-y: scroll;
  height: 100%;
}
.orders-full-cart__total {
  height: 32px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  font-weight: bold;
  color: white;
  background-color: var(--ion-color-secondary);
}
.orders-full-cart__validation {
  cursor: pointer;
  background-color: var(--ion-color-primary);
  color: white;
  font-weight: bold;
  padding: 8px;
  text-align: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.orders-full-cart__return {
  cursor: pointer;
  background-color: #fff;
  color: var(--ion-color-primary);
  font-weight: bold;
  padding: 8px;
  text-align: center;
  border-style: solid;
  border-width: 1px;
}

.orders-full-cart__products__spacer {
  width: 100%;
  height: 1px;
}
