.orders-planned-order-time-selector__container {
  margin: auto;
  height: 82%;
  /* max-width: 24rem; */
  max-width: 384px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1%;
}

.react-datepicker__time-list-item--disabled {
  display: none;
}

.orders-planned-order-time-selector--not-available {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 60%;
  margin: 2rem;
  text-align: center;
}
.orders-planned-order-time-selector {
  width: 100%;
  margin: auto;
  max-width: 1440px;
  font-size: 1.125rem;
  font-weight: bold;
  color: var(--ion-color-secondary);
  display: flex;
  flex-direction: column;
}

.orders-planned-order-time-selector-text {
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.orders-planned-order-time-datepicker {
  margin: 0 auto;
  border-radius: 0.3rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.orders-planned-order-time-datepicker-time {
  background-color: var(--ion-color-primary);
}

.orders-planned-order-time-datepicker-wrapper {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.orders-time-selector__button-container {
  /* display: flex;
  flex-direction: column; */
  width: 100%;
}

.orders-planned-order-time-selector__button-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* margin: auto; */
}

.orders-planned-order-time-selector__button-container div {
  width: 42%;
}
