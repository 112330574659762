.order-tracking-item__main-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0.5rem;
  margin: 2rem 0;
  width: 420px;
  border-style: solid;
  border-width: 1px;
  border-color: var(--ion-color-secondary);
}

.order-tracking-item__customer-info-container {
  background-color: var(--ion-color-secondary);
  padding: 0.5rem 1rem;
  border-radius: 0.4rem;
  color: #fff;
  width: 100%;
  margin-bottom: 2rem;
}

.order-tracking-item__order-number-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  background-color: var(--ion-color-secondary);
  padding: 0.5rem 1rem;
  border-radius: 0.4rem;
  color: #fff;
  width: 100%;
  margin-bottom: 2rem;
}
.order-tracking-item__customer-info-text {
  opacity: 0.8;
}

.order-tracking-item__order-info-container {
  width: 100%;
  padding: 0.5rem;
}

.order-tracking-item__order-status {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.order-tracking-item__order-status-circle-orange {
  height: 10px;
  width: 10px;
  background-color: orange;
  border-radius: 50%;
  margin: 0 0.4rem;
}
.order-tracking-item__order-status-circle-gray {
  height: 10px;
  width: 10px;
  background-color: gray;
  border-radius: 50%;
  margin: 0 0.4rem;
}
.order-tracking-item__order-status-circle-red {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  margin: 0 0.4rem;
}
.order-tracking-item__order-status-circle-green {
  height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 50%;
  margin: 0 0.4rem;
}

.order-tracking-item__order-status-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.3rem 0;
}

.order-tracking-item__order-status-text-small {
  padding: 0.5rem 0;
  font-size: 0.9rem;
  font-weight: 300;
  font-style: normal;
}

.order-tracking-item__order-total-fees {
  padding-left: 0.5rem;
  font-size: 0.9rem;
  font-weight: normal;
}
.order-tracking-item__order-status-text {
  font-weight: light;
  font-size: 0.9rem;
}

.order-tracking-item__order-status-text-big {
  font-size: 1.1rem;
}

.order-tracking-item__order-favorite-container {
  padding: 2rem 0.4rem;
  text-align: center;
}

.order-tracking-item__order-favorite-button {
  padding: 0.5rem 1rem;
  margin: 1rem;
  background-color: var(--ion-color-primary);
  color: #fff;
  cursor: pointer;
  text-align: center;
  width: auto;
  border-bottom-right-radius: 1rem;
  border-top-left-radius: 1rem;
}

.order-tracking-item__order-favorite-button:hover {
  opacity: 0.7;
}

.order-tracking-item__order-total-container {
  display: flex;
  justify-content: space-between;
  background-color: var(--ion-color-secondary);
  width: 100%;
  padding: 0.5rem 1rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.order-tracking-item__order-total-text {
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.order-tracking-item__order-cart-container {
  margin: 1rem 0;
  padding: 1rem 0.5rem;
  background-color: #e0e0e0;
  width: 100%;
  border-radius: 0.5rem;
}

.order-tracking-item__order-call-button {
  text-decoration: none;
  background-color: #fff;
  color: var(--ion-color-primary);
  padding: 0.5rem 1rem;
  border-style: solid;
  border-color: var(--ion-color-primary);
  border-width: 2px;
  border-bottom-left-radius: 1rem;
  border-top-right-radius: 1rem;
  cursor: pointer;
  /* width: 38%; */
}
.order-tracking-item__order-call-button:hover {
  opacity: 0.8;
}
.order-tracking-item__order-confirm-button {
  text-decoration: none;
  background-color: var(--ion-color-primary);
  color: #fff;
  padding: 0.5rem 1rem;
  border-style: solid;
  border-color: var(--ion-color-primary);
  border-width: 2px;
  border-bottom-left-radius: 1rem;
  border-top-right-radius: 1rem;
  cursor: pointer;
  /* width: 60%; */
}
.order-tracking-item__order-confirm-button:hover {
  opacity: 0.8;
}
.order-tracking-item__order-call-button:hover {
  opacity: 0.7;
}

@media screen and (max-width: 450px) {
  .order-tracking-item__main-container {
    width: 320px;
  }
}
