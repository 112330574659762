.base-button {
  margin: 0.5rem 0;
}
.base-button--big {
  font-size: 1.25rem;
}
.base-button--tight {
  font-size: 1rem;
  margin: 0;
}
