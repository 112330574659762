.shop-page__header__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 1rem;
}

.shop-page__header_order-tracking-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 0.75rem;
  padding-top: 0.5rem;
}
.shop-page__header_order-logo-container {
  position: relative;
  cursor: pointer;
}

.shop-page__header_order-logo-red-dot {
  position: absolute;
  bottom: 10%;
  right: 8%;
  width: 12px;
  height: 12px;
  background-color: red;
  border-radius: 50%;
}
.shop-page__header_order-tracking-logo {
  width: 28px;
  height: 28px;
  padding: 0.75rem;
  background-color: var(--ion-color-secondary);
  color: #fff;
  border-radius: 27px !important;
  overflow: hidden;
}

.shop-page__header_restaurant-info-logo {
  width: 24px;
  height: 24px;
  padding-left: 0.25rem;
  color: var(--ion-color-secondary);
  border-radius: 27px !important;
  overflow: hidden;
}

.shop-page__header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0;
  padding-top: 1rem;
  /* height: 13%; */
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  width: 100%;
  /* margin-bottom: 16px; */
}
.shop-page__header__title {
  font-size: 22px;
  font-weight: bold;
  color: var(--ion-color-secondary);
}
.shop-page__header_restaurant-logo-container {
  width: 54px;
  height: 54px;
  margin: 0 1rem 0 0;
  border-radius: 27px !important;
  overflow: hidden;
  /* border-radius: 2rem; */
}
.shop-page__header_restaurant-logo-container ion-img {
  width: 54px;
  height: 54px;
  object-fit: cover;
  object-position: center;
  /* border-radius: 2rem; */
}

.shop-page__header_order-order-tracking-text {
  text-align: center;
  font-size: 0.75rem;
  font-weight: 600;
}
@media screen and (max-height: 600px) {
  .shop-page__header__title {
    font-size: 18px;
  }
}
