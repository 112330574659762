.card-selection-container {
  min-height: 48px;
  width: 30%;
  margin: 1%;
  position: relative;
}
.card-selection-button {
  cursor: pointer;
  opacity: 1;
  transition: opacity 250ms ease;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  padding: 0.5rem;
  margin: 1%;
  border-radius: 1.25rem 4px 1.25rem 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  color: var(--ion-color-primary);
  background-color: var(--ion-color-secondary);
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
}
.card-selection-button__ion-img-container {
  /* height: 20px; */
  width: 100%;
  min-height: 16px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.card-selection-button__ion-img-image {
  /* height: 20px; */
  height: 32px;
  width: 32px;
}

.card-selection-button__ion-img-card-type {
  font-size: 0.8rem;
  margin-left: 0.5rem;
}

.card-selection-button__ion-img-card-last4 {
  font-size: 0.75rem;
  font-weight: 400;
}

.card-selection-button__ion-img-card-expiry {
  font-size: 0.7rem;
  font-weight: 200;
  opacity: 0.5s;
}

.card-selection-button--not-selected {
  opacity: 0.5;
  filter: grayscale(100%);
}
/* .card-selection-button__label {
    color: var(--ion-color-secondary);
    font-weight: 700;
    font-size: 1.125rem;
  } */
.card-selection-button--selected {
  /* color:  var(--ion-color-primary);
    background-color: var(--ion-color-secondary); */
}

@media screen and (max-width: 420px) {
  .card-selection-container {
    width: 48%;
  }
}
