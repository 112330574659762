.customer-address-selector {
  /* background-color: red; */
  height: calc(100% - 88px);
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  padding: 16px;
  max-width: 600px;
  margin: auto;
}
.customer-addresse-selector__form {
  box-shadow: var(--base-card-shadow);
  padding: 16px;
  border-radius: 8px;
}

.customer-address-selector__form__title {
  font-size: 20px;
  font-weight: bold;
  color: #364051;
}
