.orders-cart-item {
  border: 1px solid var(--ion-color-secondary);
  padding: 0.5rem;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0.5rem;
  color: var(--ion-color-secondary);
  cursor: pointer;
}
.orders-cart-item__base-product-infos-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.orders-cart-item__base-product-infos {
  display: flex;
  justify-content: space-between;
}

.orders-cart-item__base-product-quantity-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 0 0.25rem;
}

.orders-cart-item__base-product-quantity-text {
  font-weight: bold;
  font-size: 0.825rem;
  width: 100%;
}
.orders-cart-item__base-product-quantity-amount {
  font-weight: bold;
  font-size: 0.825rem;
}
.orders-cart-item__base-product-infos__product-picture {
  height: 32px;
  min-width: 32px;
  width: 32px;
  border-top-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid var(--ion-color-primary);
  overflow: hidden;
  margin-right: 0.5rem;
}
.orders-cart-item__base-product-infos__product-picture ion-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.orders-cart-item__base-product-infos__name {
  font-weight: bold;
  font-size: 0.825rem;
  width: 100%;
}
.orders-cart-item__base-product-infos__price {
  font-weight: bold;
  font-size: 0.825rem;
  margin-left: 0.5rem;
  align-self: flex-end;
  text-align: end;
}
.orders-cart-item__action-container {
  margin-top: 1rem;
}
.orders-cart-item__options {
  padding: 16px 0 0 0;
}
.orders-cart-item__options__item {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  opacity: 0.7;
  font-size: 12px;
  padding-left: 40px;
}
.orders-cart-item__options__item__name {
  display: flex;
  align-items: center;
}
.orders-cart-item__options__item__dot {
  height: 8px;
  width: 8px;
  background-color: var(--ion-color-secondary);
  border-radius: 100%;
  margin-right: 8px;
}
