.payment-method-container {
  color: var(--ion-color-secondary);
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
  margin-top: 0.25rem;
}

.payment-method-container-options {
  display: flex;
  flex-wrap: wrap;
  padding-top: 0.65rem;
  padding-bottom: 1rem;
}

.payment-method-item {
  padding: 1rem;
  width: 33.333%;
  word-wrap: break-word;
  box-sizing: border-box;
}

.payment_method_img {
  width: 40px;
  height: 25px;
  background-color: transparent;
}
